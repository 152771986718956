import styled from "styled-components";
import { ReactComponent as MascotPrimary } from "../../../assets/illustrations/mascotte-primary.svg";
import { useEffect, useMemo, useState } from "react";

const Container = styled.div`
  display: flex;
  width: 100svw;
  height: 100svh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1d1d1b;
`;

const Label = styled.p`
  color: var(--Text-Reverse, #fff);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  /* Mobile/Body */
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.2px;
`;

const DiagnosticWaitResults = () => {

  const tips = useMemo(() => {
    return [
      "Analyse du tracé en cours",
      "Calcul des paramètres",
      "Traitement des données",
      "Diagnostic en approche",
    ];
  }, []);

  const [index, setIndex] = useState(0);
  const [currentTip, setCurrentTip] = useState(tips[index]);

  useEffect(() => {
    setCurrentTip(tips[index]);
  }, [index, tips]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (index >= tips.length -1 ) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }
    }, 2500);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTip]);

  return (
    <Container>
      <MascotPrimary />
      <Label>{`${currentTip}...`}</Label>
      <div className="loader">
        <div className="loaderBar"></div>
      </div>
    </Container>
  );
};

export default DiagnosticWaitResults;
