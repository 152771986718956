import { breakpoint } from "styled-components-breakpoint";
import styled from "styled-components";
import { ReactComponent as NemoLogo } from "../../../assets/logos/nemo-logo.svg";
import { ReactComponent as NemoLogoWhite } from "../../../assets/logos/nemo-logo-white.svg";
import { ReactComponent as CloseIcon } from "../../../_icons/close.svg";
import { ReactComponent as BurgerIcon } from "../../../_icons/burger.svg";
import { NavLink, useNavigate } from "react-router-dom";
import Monogram from "../monogram";
import { useAuthContext } from "../../../_contexts/auth";
import { logout } from "../../../_services/api/auth";
import { REACT_APP_ENVIRONMENT, REACT_APP_VERSION } from "../../../_config/env";
import { useEffect, useRef, useState } from "react";
import { ROUTES } from "../../../router/routes";

const SideBarWrapper = styled.div<{ $displayMenu: boolean }>`
  background: var(
    --Primary-Gradient-PG,
    linear-gradient(76deg, #1d1d1d 0%, #1d1d1d 100%)
  );
  transition: transform 0.4s;
  color: white;
  position: absolute;
  height: 100svh;
  z-index: 1;
  max-width: 260px;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;

  ${breakpoint("md" as any)`
    position: relative;
    z-index: 0;
    transform: translateX(0);
    `}

  transform: ${(props) => {
    if (!props.$displayMenu) {
      return "translateX(-260px)";
    }
  }};

  .icon-close-wrapper {
    position: absolute;
    display: ${(props) => (props.$displayMenu ? "inline-block" : "none")};
    cursor: pointer;
    top: 8px;
    right: 16px;
    padding: 5px;
    transition: transform 0.3s 0.05s, opacity 0.25s;
    transform: ${(props) =>
      !props.$displayMenu ? "none" : "scale(0.8) rotate(180deg)"};
    opacity: ${(props) => (props.$displayMenu ? 1 : 0)};

    ${breakpoint("md" as any)`
      display: none;
    `}
  }
`;

const LabelWrapper = styled.div`
  margin: auto 0;
`;

const MenuWrapper = styled.ul`
  margin: 0;
  list-style: revert;
  width: auto;
  padding: 0 24px;

  > li > ul {
    display: none;
  }

  > li > .active + ul {
    display: block;
  }
`;

const MenuStyled = styled.aside<{ $displayMenu: boolean }>`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  //width: 260px;
  width: 100%;
  height: 100%;
  max-height: 100svh;
  overflow-y: auto;
  flex-direction: column;
  padding: 24px 0;
  justify-content: space-between;

  nav {
    display: flex;
    flex-direction: column;
    width: auto;
    max-width: 100%;
    margin: 0;
    flex: 1;
    //justify-content: space-between;
    //height: 100%;
  }
`;

const ListElement = styled.li<{ $fontSize?: number; $marginVertical?: number }>`
  font-size: ${(props) => props.$fontSize}px;
  font-weight: bold;
  margin-top: ${(props) => props.$marginVertical}rem;
  margin-bottom: ${(props) => props.$marginVertical}rem;
  line-height: ${(props) => props.$fontSize}px;
  display: flex;
  justify-content: flex-start;

  a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #fff;
    opacity: 0.6;
    text-decoration: none;
    padding: 3px 10px 3px 10px;
    box-sizing: border-box;
    transition: opacity 300ms ease, transform 300ms ease;

    // Active indicator
    &:before {
      content: "";
      position: absolute;
      border-radius: 3px;
      inset: 0;
      background-color: #fff;
      width: 3px;
      height: 100%;
      transform: scale3d(0, 1, 1);
      transform-origin: left;
      transition: transform 300ms ease;
    }

    svg {
      margin-right: 1rem;
      min-width: 24px;
    }

    &:hover,
    &.active {
      opacity: 1;
      transform: translate3d(5px, 0, 0);

      &:before {
        transform: scale3d(1, 1, 1);
      }
    }
  }
`;

const IconBurgerWrapper = styled.span<{ $displayBurger: boolean }>`
  display: flex;
  cursor: pointer;
  transition: opacity 0.4s ease-out;
  opacity: ${(props) => (props.$displayBurger ? "1" : "0")};
  position: absolute;
  left: 0 svg {
    overflow: visible;
    transition: transform 0.4s;
    transform: ${(props) =>
      props.$displayBurger ? "none" : "translateX(-300px)"};
  }

  svg line {
    transition: transform 0.4s;
    transform: ${(props) =>
      props.$displayBurger ? "none" : "translateX(300px)"};

    &:nth-of-type(2) {
      transition-delay: 0.025s;
    }

    &:nth-of-type(3) {
      transition-delay: 0.05s;
    }
  }
`;

const TopBarStyled = styled.div`
  position: relative;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;

  ${breakpoint("md" as any)`
    display: none;
  `}
`;

const TopBar = ({ setDisplayMenu, displayMenu }) => {
  const navigate = useNavigate();

  return (
    <TopBarStyled>
      <IconBurgerWrapper
        onClick={() => {
          setDisplayMenu(!displayMenu);
        }}
        $displayBurger={!displayMenu}
      >
        <BurgerIcon width={37} />
      </IconBurgerWrapper>
      <NemoLogo
        width={111}
        height={26}
        onClick={() => navigate(ROUTES.DASHBOARD())}
        style={{ cursor: "pointer" }}
      />
    </TopBarStyled>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  height: 100svh;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 10px 5px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100svh;
  width: 100%;
  height: 100svh;

  ${breakpoint("md" as any)`
    padding: 20px 20px 100px 20px;
  `}
`;

const makeMenu = (children, margin = 0.6, fontSize = 22, iconWidth = 24) =>
  children?.length > 0 && (
    <MenuWrapper>
      {children.map(
        (
          { link, label, Icon, onClick, target, external, exactPath },
          index
        ) => (
          <ListElement
            key={index}
            $marginVertical={margin}
            $fontSize={fontSize}
          >
            {external ? (
              <a href={link} rel="noreferrer" target="_blank">
                {Icon && <Icon width={iconWidth} height={iconWidth} />}
                <LabelWrapper>{label}</LabelWrapper>
              </a>
            ) : (
              <NavLink
                to={external ? { pathname: link } : link}
                target={target}
                rel={target === "_blank" ? "noreferrer" : ""}
                onClick={(e) => onClick && onClick(e)}
                end={exactPath}
              >
                {Icon && <Icon width={iconWidth} height={iconWidth} />}
                <LabelWrapper>{label}</LabelWrapper>
              </NavLink>
            )}
          </ListElement>
        )
      )}
    </MenuWrapper>
  );

const Menu = ({ itemsList, displayMenu }) => (
  <MenuStyled $displayMenu={displayMenu}>
    <nav>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {makeMenu(itemsList)}
      </div>
    </nav>
    <div style={{ textAlign: "center" }}>
      <span>
        {REACT_APP_ENVIRONMENT} - {REACT_APP_VERSION}
      </span>
    </div>
  </MenuStyled>
);

const SideBar = ({ itemsList, displayMenu, setDisplayMenuMobile }) => {
  const hasMenu = itemsList?.length > 0;
  const { user } = useAuthContext();
  const wrapperRef = useRef(null);

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setDisplayMenuMobile(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <SideBarWrapper ref={wrapperRef} $displayMenu={displayMenu}>
      <span className="icon-close-wrapper">
        <CloseIcon width={24} onClick={() => setDisplayMenuMobile(false)} />
      </span>
      <NemoLogoWhite
        width="50%"
        style={{ display: "block", margin: "10px auto" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 8,
          padding: "0 24px",
        }}
      >
        <Monogram $backgroundImageUrl={user.avatar?.url || ""}>
          {user.first_name.charAt(0)}
        </Monogram>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <h3 style={{ margin: 0 }}>
            {user.first_name} {user.last_name}
          </h3>
          <div
            onClick={async () => await logout()}
            style={{ cursor: "pointer" }}
          >
            Se déconnecter
          </div>
        </div>
      </div>
      {hasMenu && <Menu itemsList={itemsList} displayMenu={displayMenu} />}
    </SideBarWrapper>
  );
};

const AdminLayout = ({ items, children }) => {
  const [displayMenu, setDisplayMenuMobile] = useState(false);

  const itemsList = items.map((item) => ({
    ...item,
    onClick: (e) => {
      item.onClick?.(e);
      setDisplayMenuMobile(false);
    },
  }));

  return (
    <PageWrapper>
      <SideBar
        itemsList={itemsList}
        setDisplayMenuMobile={setDisplayMenuMobile}
        displayMenu={displayMenu}
      />
      <PageContent>
        <TopBar
          setDisplayMenu={setDisplayMenuMobile}
          displayMenu={displayMenu}
        />
        {children}
      </PageContent>
    </PageWrapper>
  );
};

export default AdminLayout;
