import { PageContainer } from "../../../_ui/page";
import TabbedNavigation from "../../../_ui/tabbed-navigation";
import { useState } from "react";
import {
  useDeletePathology,
  usePathologies,
} from "../../../../_hooks/pathology/use-pathologies";
import {
  APIListPayload,
  APISortingObject,
} from "../../../../_services/api/_helpers/api-list-types";
import { ReactComponent as TrashIcon } from "../../../../_icons/trash.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../router/routes";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  PathologyStatusCaption,
  PathologyRecurrentCaption,
} from "../_commons/captions";
import Button from "../../../_ui/button";
import { debounce } from "lodash";
import { TextField } from "../../../_ui/form";
import PaginationDisplay from "../../../_ui/pagination";

const DeleteCell = ({ id, onDelete }) => {
  const { deletePathology } = useDeletePathology();
  return (
    <TrashIcon
      color={"#FF453A"}
      onClick={async () => {
        if (
          id &&
          window.confirm("Voulez-vous vraiment supprimer cet élément?")
        ) {
          await deletePathology(id);
          onDelete?.();
        }
      }}
      // width={40}
      height={"100%"}
      style={{ cursor: "pointer" }}
    />
  );
};

const PathologiesTable = ({ payload }: { payload?: APIListPayload }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<APISortingObject>([
    {
      name: "id",
      direction: "desc",
    },
  ]);
  const [search, setSearch] = React.useState("");

  const { getPathologies, pathologies, isLoading } = usePathologies({
    page_size: 20,
    page_number: currentPage,
    filters: { ...payload.filters, search: [search] },
    sorting: sorting,
  });

  const data = { nodes: pathologies?.list || [] };

  // -------- Pagination / Sorting / Search

  const pagination = usePagination(
    data,
    {
      state: {
        page: 0,
      },
      onChange: onPaginationChange,
    },
    { isServer: true }
  );

  function onPaginationChange(action, state) {
    // refresh page with payload page value = action.payload.page +1
    setCurrentPage(action.payload.page + 1);
  }

  const handleSearch = (event) => {
    // refresh page with input search value = event.target.value
    setSearch(event.target.value);
  };

  const onInputChange = React.useMemo(() => debounce(handleSearch, 1000), []);

  const sort = useSort(
    data,
    {
      onChange: onSortChange,
    },
    {
      sortFns: {},
      isServer: true,
    }
  );

  function onSortChange(action, state) {
    // refresh page with search state value = state.sortKey / state.reverse
    setSorting([
      {
        name: state.sortKey,
        direction: state.reverse ? "desc" : "asc",
      },
    ]);
  }

  // -- End Pagination / Sorting / Search

  const handleCellClick = (data) => {
    navigate(ROUTES.PATHOLOGIES(data.id));
  };

  const theme = useTheme([
    getTheme(),
    {
      Table: `
        --data-table-library_grid-template-columns: 60px 90px minmax(200px, 1fr) minmax(100px, 150px) minmax(100px, 120px) minmax(120px, 160px) 50px;
      `,
      HeaderCell: `
        z-index: 0;
      `,
    },
  ]);

  const COLUMNS = [
    {
      label: "ID",
      renderCell: (item) => item.id.toString(),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "id" },
    },
    {
      label: "Statut",
      renderCell: (item) => (
        <PathologyStatusCaption className={item.status}>
          {t(`enums.pathology.status.${item.status}`)}
        </PathologyStatusCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "status" },
    },
    {
      label: "Label",
      renderCell: (item) => <span title={item.text}>{item.text}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "text" },
    },
    {
      label: "Pop-over",
      renderCell: (item) => <span title={item.popover}>{item.popover}</span>,
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "popover" },
    },
    {
      label: "Récurrent",
      renderCell: (item) => (
        <PathologyRecurrentCaption className={item.recurrent.toString()}>
          {t(`enums.pathology.recurrent.${item.recurrent.toString()}`)}
        </PathologyRecurrentCaption>
      ),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "recurrent" },
    },
    {
      label: "Date de création",
      renderCell: (item) =>
        new Date(item.created_at).toLocaleDateString("fr-fr"),
      cellProps: {
        onClick: (item) => () => handleCellClick(item),
        style: {
          textAlign: "left",
        },
      },
      sort: { sortKey: "created_at" },
    },
    {
      label: "",
      renderCell: (item) => (
        <DeleteCell
          id={item.id}
          onDelete={() => setTimeout(() => getPathologies(), 200)}
        />
      ),
    },
  ];

  return (
    <>
      <TextField
        type="text"
        label="Rechercher une pathologie"
        placeholder={""}
        onChange={(e) => {
          onInputChange(e);
        }}
      />
      <br />
      {isLoading ? (
        <span className="spin-loader"></span>
      ) : (
        <div style={{ textAlign: "left" }}>
          <CompactTable
            columns={COLUMNS}
            data={data}
            theme={theme}
            sort={sort}
            pagination={pagination}
            layout={{
              custom: true,
              horizontalScroll: true,
              fixedHeader: true,
            }}
          />
        </div>
      )}
      <br />
      <PaginationDisplay pagination={pagination} nodes={data.nodes} />
    </>
  );
};

const categories = {
  neurology: <span>neurology</span>,
  musculotendinous: <span>musculotendinous</span>,
  osteoarticular: <span>osteoarticular</span>,
  other: <span>other</span>,
};

const PathologiesPage = () => {
  const [activeTab, setActiveTab] = useState<string>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const items = Object.keys(categories).map((category) => ({
    key: category,
    element: <span>{t(`enums.pathology.category.${category}`)}</span>,
    onClick: () => setActiveTab(category),
  }));

  const categoryKey = activeTab || items[0]?.key;

  return (
    <PageContainer
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        gap: 20,
      }}
    >
      <TabbedNavigation
        items={items}
        defaultActive={items.findIndex((item) => item.key === categoryKey)}
      />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button onClick={() => navigate(ROUTES.PATHOLOGIES("new"))}>
          Ajouter
        </Button>
      </div>
      <div style={{ height: "100%" }}>
        {categoryKey && (
          <PathologiesTable
            payload={{ filters: { category: [categoryKey] } }}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default PathologiesPage;
