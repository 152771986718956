import Human from '../../../_ui/human';
import {useEffect, useRef, useState} from 'react';
import {useBodyParts} from '../../../../_hooks/body-part/use-body-parts';
import {DiagnosticAPIService} from '../../../../_services/api';
import {APIBodyPart, APIRequestPathologyMatch} from '../../../../_services/api/_helpers/api-types';
import styled from 'styled-components';
import BottomBar from '../../../_ui/bottom-bar';
import {ROUTES} from '../../../../router/routes';
import {ReactComponent as NemoButtonIcon} from '../../../../_icons/nemo_button.svg';
import {ReactComponent as FrontIcon} from '../../../../_icons/front.svg';
import {ReactComponent as BackIcon} from '../../../../_icons/back.svg';
import {ReactComponent as HeadIcon} from '../../../../_icons/head.svg';
import {ReactComponent as FootIcon} from '../../../../_icons/foot.svg';
import {ReactComponent as UndoIcon} from '../../../../_icons/undo.svg';
import {ReactComponent as RedoIcon} from '../../../../_icons/redo.svg';
import {ReactComponent as ResetIcon} from '../../../../_icons/reset.svg';
import {ReactComponent as HelpIcon} from '../../../../_icons/help.svg';
import {ReactComponent as NavigateBeforeIcon} from '../../../../_icons/navigate_before.svg';
import {useNavigate, useParams} from 'react-router-dom';
import {useDiagnostic} from '../../../../_hooks/diagnostic/use-diagnostics';
import {useAuthContext} from '../../../../_contexts/auth';
import MascotModal from '../../../_ui/mascot-modal';
import DrawActionButton from '../../../_ui/draw-action-button';
import BrushSizeSelect from '../../../_ui/brush-size-select';
import HelperModal from '../../../_ui/helper-modal';
import DiagnosticWaitResults from '../../../_ui/diagnostic-wait-results';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100svh - 86px);
`;

const DiagnosticPage = () => {
  const navigate = useNavigate();
  const pictureRef = useRef();
  const {id} = useParams();
  const {user} = useAuthContext();
  const {diagnostic, isLoading} = useDiagnostic({id});
  const [bodyPart, setBodyPart] = useState<APIBodyPart | null>(null);
  const [payload, setPayload] = useState<APIRequestPathologyMatch[]>([]);
  const [imageStates, setImageStates] = useState<object>({});
  const [openModal, setOpenModal] = useState<string | null>(null);
  const [openHelpModal, setOpenHelpModal] = useState<string | null>(null);
  const {bodyParts} = useBodyParts();
  const [brushSize, setBrushSize] = useState<number>(40);
  const diagnosticVersion = diagnostic?.diagnostic_versions?.[0];

  const [operationInProgress, setOperationInProgress] = useState(false)

  const getActions = () => {
    return pictureRef.current;
  };

  useEffect(() => {
    // @ts-ignore
    getActions()?.setBrushWidth?.(brushSize);
  }, [brushSize]);

  useEffect(() => {
    if (diagnosticVersion?.payload) {
      setPayload(diagnosticVersion.payload as APIRequestPathologyMatch[]);
      const states = {};
      diagnosticVersion.payload.forEach((d) => states[d.body_part_id.toString()] = d.image_state_editor);
      setImageStates(states);
    }
  }, [diagnosticVersion]);

  if (isLoading || operationInProgress){
    return <DiagnosticWaitResults/>;
  }

  const currentBodyPart = bodyPart || bodyParts?.list?.[0];

  const saveData = async () => {
    // @ts-ignore
    const res = await getActions()?.processImage();
    const newItems = new RegExp('[1-9]').test(res.binary_area) ? [{
      body_part_id: currentBodyPart.id,
      binary_area: res.binary_area,
      image_state_editor: res.image_state_editor,
    }] : [];

    await setPayload(prev => ([
      ...prev.filter((obj) => obj.body_part_id !== currentBodyPart.id),
      ...newItems,
    ]));
    setImageStates(prev => ({...prev, [currentBodyPart.id?.toString()]: res.image_state_editor}));
    return newItems;
  };

  const requestApi = async (confirmed = false) => {
    setOperationInProgress(true)
    const newItems = await saveData();

    const requestBody = [
      ...payload.filter((obj) => obj.body_part_id !== newItems[0]?.body_part_id),
      ...newItems
    ];

    if (requestBody.length <= 0) {
      setOperationInProgress(false)
      setOpenModal('emptyDrawGuardianModal');
      return;
    }

    if (!confirmed && user.plan !== 'premium') {
      setOperationInProgress(false)
      setOpenModal('tokensModal');
      return;
    }

    const requestPayload = {
      diagnostic_versions: [
        {
          quick: true,
          payload: requestBody,
        },
      ]
    };
    if (id && id !== 'new') {
      requestPayload['id'] = id;
    }
    const resultsApi = await DiagnosticAPIService[requestPayload['id'] ? 'updateDiagnostic' : 'createDiagnostic'](requestPayload).catch(e => {
        if (e.response.data?.type === 'diagnostic_version_error') {
          setOpenModal('diagnosticVersionErrorModal');
        }
    });
    if (resultsApi) {
      navigate(ROUTES.DIAGNOSTIC_RESULT(resultsApi.id));
    }
    setOperationInProgress(false)
  };

  const changeBodyPart = async (label: string) => {
    const newBodyPart = bodyParts?.list?.find(bp => bp.label === label);
    await saveData();
    setBodyPart(newBodyPart);
  };

  const resetBodyParts = () => {
    // @ts-ignore
    getActions()?.clear();
    setImageStates({});
  }

  const activeStyle = {backgroundColor: '#ADE82F', borderRadius: 10, height: 40, width: 40};
  const inactiveStyle = {color: '#B8B8B8', borderRadius: 10, height: 40, width: 40};

  const menuItems = [
    {
      Icon: FrontIcon,
      external: false,
      link: '#front',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        await changeBodyPart('front')
      },
      style: ({isActive}) => (
        isActive && currentBodyPart?.label === 'front' ? activeStyle : inactiveStyle
      ),
    },
    {
      Icon: BackIcon,
      external: false,
      link: '#back',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        await changeBodyPart('back')
      },
      style: ({isActive}) => (
        isActive && currentBodyPart?.label === 'back' ? activeStyle : inactiveStyle
      ),
    },
    {
      Icon: NemoButtonIcon,
      external: false,
      link: undefined,
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        await requestApi();
      },
      style: {width: 58, height: 58},
      iconSize: {width: 58, height: 58},
    },

    {
      Icon: HeadIcon,
      external: false,
      // link: '#face',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        await changeBodyPart('face')
      },
      style: ({isActive}) => (
        isActive && currentBodyPart?.label === 'face' ? activeStyle : inactiveStyle
      ),
    },
    {
      Icon: FootIcon,
      external: false,
      // link: '#foot',
      target: undefined,
      onClick: async (e) => {
        e.preventDefault();
        await changeBodyPart('foot')
      },
      style: ({isActive}) => (
        isActive && currentBodyPart?.label === 'foot' ? activeStyle : inactiveStyle
      ),
    },
  ];

  const closeModalActions = {
    helpModal: () => setOpenModal(null),
    guardianModal: () => navigate(-1),
    emptyDrawGuardianModal: () => setOpenModal(null),
    tokensModal: async () => await requestApi(true),
    diagnosticVersionErrorModal: () => setOpenModal(null),
  };

  return (
    <div style={{position: 'relative', paddingBottom: 100}}>
      <div>
        <Container>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {currentBodyPart && (
              <div
                style={{
                  flex: 1,
                  position: 'relative',
                  height: 'calc(100% - 86px)',
                }}
              >
                {currentBodyPart?.id && (
                  <Human
                    key={currentBodyPart.id}
                    ref={pictureRef}
                    imageStateEditor={imageStates?.[currentBodyPart?.id?.toString()]}//payload.find((obj) => obj.body_part_id === currentBodyPart?.id)?.image_state_editor}
                    bodyPart={currentBodyPart}
                    brushSize={brushSize}
                  />
                )}
                <div
                  style={{
                    position: 'absolute',
                    left: 20,
                    bottom: 20,
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 8,
                  }}
                >
                  <DrawActionButton
                    $custombg='#DCDCD9'
                    onClick={() => {
                      // @ts-ignore
                      getActions().undo();
                    }}
                  >
                    <UndoIcon/>
                  </DrawActionButton>
                  <DrawActionButton
                    $custombg='#DCDCD9'
                    onClick={() => {
                      // @ts-ignore
                      getActions().redo();
                    }}
                  >
                    <RedoIcon/>
                  </DrawActionButton>
                  <DrawActionButton
                    style={{width: 'auto'}}
                    $custombg='#DCDCD9'
                    onClick={() => {
                      resetBodyParts()
                    }}
                  >
                    <ResetIcon />
                    Réinitialiser
                  </DrawActionButton>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: 15,
                    bottom: 15,
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 8,
                    border: '1px solid #1D1D1B',
                    borderRadius: 16,
                    padding: 4,
                  }}
                >
                  <BrushSizeSelect
                    value={brushSize}
                    onChange={setBrushSize}
                    direction="column-reverse"
                  />
                </div>
                <div
                  style={{
                    position: 'absolute',
                    left: 20,
                    top: 20,
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 8,
                  }}
                >
                  <DrawActionButton
                    onClick={() => setOpenModal('guardianModal')}
                  >
                    <NavigateBeforeIcon style={{fill:"#D9D9D9"}}/>
                  </DrawActionButton>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    right: 20,
                    top: 20,
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 8,
                  }}
                >
                  <DrawActionButton
                    onClick={() => {
                      setOpenHelpModal('helpModal');
                    }}
                  >
                    <HelpIcon/>
                  </DrawActionButton>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
      <BottomBar
        style={{position: 'fixed', bottom: -10, width: '100svw'}}
        items={menuItems}
      />
      <MascotModal
        isOpen={!!openModal}
        onClose={() => setOpenModal(null)}
        onContinue={closeModalActions[openModal]}
        translationKey={`diagnosticDraw.${openModal}`}
      />
      <HelperModal
        isOpen={!!openHelpModal}
        onClose={() => setOpenHelpModal(null)}
        translationKey={`diagnosticDraw.${openHelpModal}`}
      />
    </div>
  );
};

export default DiagnosticPage;