import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';

const BottomBarContainer = styled.div<{ height?: number }>`
  display: flex;
  height: ${(props) => props.height || 76}px;
  padding: 12px 32px 22px 32px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--XL, 32px) var(--XL, 32px) 0px 0px;
  border-top: 1px solid var(--Neutral-Grey-80, #EDEDED);
  border-right: 1px solid var(--Neutral-Grey-80, #EDEDED);
  border-left: 1px solid var(--Neutral-Grey-80, #EDEDED);
  background: var(--Background-surface-0, #FFF);
  /* Drop Shadow/xl */
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
`;

const NavLinkStyled = styled(NavLink)`
  box-sizing: border-box;
  color: #D9D9D9;
  
  &.active {
    color: #1D1D1B;
  }
`;

const BottomBar = ({ items, ...rest }) => {
  const location = useLocation();

  const links = items.map((item, index) => {
    const isLinkActive = item.link === location.pathname;
    let Icon;

    if (item.IconActive && isLinkActive) {
      Icon = item.IconActive;
    }
    else if (!item.IconActive && isLinkActive) {
      Icon = item.Icon;
    }
    else if (item.Icon && !isLinkActive) {
      Icon = item.Icon;
    }
    else {
      Icon = null;
    }

    return (
      <NavLinkStyled
        key={index}
        to={item.external ? { pathname: item.link } : item.link}
        end
        target={item.target}
        rel={item.target === '_blank' ? 'noreferrer' : ''}
        onClick={e => item.onClick && item.onClick(e)}
        style={item.style}
      >
        {Icon && (
          <Icon
            width={item?.iconSize?.width || 40}
            height={item?.iconSize?.height || 40}
          />
        )}
      </NavLinkStyled>
    )
  })

  return (
    <BottomBarContainer {...rest}>
      {links}
    </BottomBarContainer>
  )
};

export default BottomBar