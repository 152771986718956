import * as React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { PageContainer } from "../../../_ui/page";
import { useBalanceTransactions } from "../../../../_hooks/balance-transaction/use-balance-transactions";
import {
  APIListPayload,
  APISortingObject,
} from "../../../../_services/api/_helpers/api-list-types";
import { forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import {
  BalanceTransactionCategoryCaption,
  BalanceTransactionUnitCaption,
} from "../_commons/captions";
import { useSort } from "@table-library/react-table-library/sort";
import { usePagination } from "@table-library/react-table-library/pagination";
import { debounce } from "lodash";
import { TextField } from "../../../_ui/form";
import PaginationDisplay from "../../../_ui/pagination";

const COLUMNS = (handleCellClick, t) => [
  {
    label: "User",
    renderCell: (item) => `${item.user?.first_name} ${item.user?.last_name}`,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
  },
  {
    label: "Catégorie",
    renderCell: (item) => (
      <BalanceTransactionCategoryCaption className={item.category}>
        {t(`enums.balance_transactions.category.${item.category}`)}
      </BalanceTransactionCategoryCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
    sort: { sortKey: "category" },
  },
  {
    label: "Quantité",
    renderCell: (item) => item.quantity,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
    sort: { sortKey: "quantity" },
  },
  {
    label: "Unité",
    renderCell: (item) => (
      <BalanceTransactionUnitCaption className={item.unit}>
        {t(`enums.balance_transactions.unit.${item.unit}`)}
      </BalanceTransactionUnitCaption>
    ),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
    sort: { sortKey: "unit" },
  },
  {
    label: "Date",
    renderCell: (item) =>
      new Date(item.created_at).toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
    sort: { sortKey: "created_at" },
  },
  {
    label: "Reason",
    renderCell: (item) => item.reason,
    cellProps: {
      onClick: (item) => () => handleCellClick(item),
    },
    sort: { sortKey: "reason" },
  },
];

const BalanceTransactionsTable = forwardRef(
  ({ payload }: { payload?: APIListPayload }, ref) => {
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [sorting, setSorting] = React.useState<APISortingObject>([
      {
        name: "id",
        direction: "desc",
      },
    ]);

    const [search, setSearch] = React.useState("");

    const { balanceTransactions, getBalanceTransactions, isLoading } =
      useBalanceTransactions({
        page_size: 20,
        page_number: currentPage,
        filters: { ...payload?.filters, search: [search] },
        sorting: sorting,
      });

    const handleCellClick = (data) => {};

    const data = { nodes: balanceTransactions?.list || [] };

    const theme = useTheme([
      getTheme(),
      {
        Table: `
        --data-table-library_grid-template-columns: 250px 120px repeat(2, 100px) 150px minmax(250px, 2fr);
      `,
        HeaderCell: `
        z-index: 0;
      `,
      },
    ]);

    useImperativeHandle(
      ref,
      () => {
        return {
          refresh: getBalanceTransactions,
        };
      },
      []
    );

    // -------- Pagination / Sorting / Search

    const pagination = usePagination(
      data,
      {
        state: {
          page: 0,
        },
        onChange: onPaginationChange,
      },
      { isServer: true }
    );

    function onPaginationChange(action, state) {
      // refresh page with payload page value = action.payload.page +1
      setCurrentPage(action.payload.page + 1);
    }

    const handleSearch = (event) => {
      // refresh page with input search value = event.target.value
      setSearch(event.target.value);
    };

    const onInputChange = React.useMemo(() => debounce(handleSearch, 1000), []);

    const sort = useSort(
      data,
      {
        onChange: onSortChange,
      },
      {
        sortFns: {},
        isServer: true,
      }
    );

    function onSortChange(action, state) {
      // refresh page with search state value = state.sortKey / state.reverse
      setSorting([
        {
          name: state.sortKey,
          direction: state.reverse ? "desc" : "asc",
        },
      ]);
    }

    // -- End Pagination / Sorting / Search

    return (
      <>
        {!payload && (
          <>
            <TextField
              type="text"
              label="Rechercher un utilisateur"
              placeholder={""}
              onChange={(e) => {
                onInputChange(e);
              }}
            />
            <br />
          </>
        )}

        {isLoading ? (
          <span className="spin-loader"></span>
        ) : (
          <div style={{ textAlign: "left" }}>
            <CompactTable
              columns={COLUMNS(handleCellClick, t)}
              data={data}
              theme={theme}
              sort={sort}
              pagination={pagination}
              layout={{
                custom: true,
                horizontalScroll: true,
                fixedHeader: true,
              }}
            />
          </div>
        )}

        <br />
        <PaginationDisplay pagination={pagination} nodes={data.nodes} />
      </>
    );
  }
);

const BalanceTransactionsPage = () => (
  <PageContainer
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: 20,
    }}
  >
    <h1>Transactions</h1>
    <BalanceTransactionsTable />
  </PageContainer>
);

export default BalanceTransactionsPage;

export { BalanceTransactionsTable };
