import Axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import { API_ROUTES, ApiClientType } from './config';

type ReadRequest = <Response>(
  path: string,
  config?: AxiosRequestConfig
) => Promise<Response>;

type WriteRequest = <Request, Response>(
  path: string,
  object: Request,
  config?: AxiosRequestConfig
) => Promise<Response>;

interface ApiClient {
  get: ReadRequest;
  post: WriteRequest;
  put: WriteRequest;
  patch: WriteRequest;
  delete: ReadRequest;
  setToken: (token: string) => void;
  unsetToken: () => void;
}


export class ApiClientBase implements ApiClient {
  protected client: AxiosInstance;

  constructor(api: ApiClientType) {
    this.client = Axios.create({ baseURL: API_ROUTES[api].BASE });
    this.client.interceptors.response.use(
      response => {
        return response;
      },
      async (error: AxiosError) => { 
        const status = error.response?.status;
        try {
          if (status === 500 || status === 400) {
            // localStorage.removeItem('user')
            window.location.href = window.location.origin+'/500';
          } else {
            return Promise.reject(error);
          }
          // return Promise.reject(error);
        } catch (errorValue) {
          return Promise.reject(errorValue);
        }
      },
    );
  }

  get<Response>(route: string, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.get(route, { signal: config?.signal, params: config?.params });
  }

  post<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.post(route, data, { signal: config?.signal });
  }

  put<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.put(route, data, { signal: config?.signal });
  }

  patch<Request, Response>(route: string, data: Request, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.patch(route, data, { signal: config?.signal });
  }

  delete<Response>(route: string, config?: AxiosRequestConfig): Promise<Response> {
    return this.client.delete(route, { signal: config?.signal });
  }

  setToken(token: string) {
    this.client.defaults.headers.common["Authorization"] = `${token}`;
    if(process.env.REACT_APP_ENV === 'development') {
      this.client.defaults.headers.common["ngrok-skip-browser-warning"] = '1';
    }
  }

  unsetToken() {
    delete this.client.defaults.headers.common["Authorization"];
  }

}