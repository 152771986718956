import { useState } from "react";
import {ReactComponent as PopoverIcon} from '../../../_icons/popover.svg';
import { Popover, ArrowContainer } from "react-tiny-popover";

const CustomPopover = ({title = '', ...rest}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={["top", "bottom", "left", "right"]} // preferred positions by priority
      padding={20} // adjust padding here!
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={15}
          arrowStyle={{ opacity: 0.7 }}
          className='popover-arrow-container'
          style={{backgroundColor:'white', borderRadius:'12px'}}
          arrowClassName='popover-arrow'
        >
          <div
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            {title}
          </div>
        </ArrowContainer>
      )}
    >
      <div style={{height:'16px'}} onClick={() => setIsPopoverOpen(!isPopoverOpen)}><PopoverIcon/></div>
    </Popover>
  );
};

export default CustomPopover;
