import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Mascot404 } from "../../../assets/illustrations/mascotte-404.svg";
import Button from "../../_ui/button";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100svh;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const Padding = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
`;

const ErrorPage = ({ redirect }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Mascot404 />
      <Padding>
        <h1>Il y a eu une erreur...</h1>
      </Padding>
      <Button onClick={() => navigate(redirect)}>Retour à l'accueil</Button>
    </Container>
  );
};

export default ErrorPage;
