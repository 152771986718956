export const ROUTES = {
  HOME: () => '/',
  LOGIN: () => '/login',
  RESET_PASSWORD: () => '/login/reset_password',
  SIGNUP_CONFIRM: () => '/sign_up/confirm',
  SIGNUP: () => '/signup',
  DASHBOARD: () => '/dashboard',
  NOTFOUND: () => '/404',
  ERROR: () => '/500',
  PROFILES: (id?) => `${ROUTES.DASHBOARD()}/profiles${id ? `/${id}` : ''}`,
  USERS: (id?) => `${ROUTES.DASHBOARD()}/users${id ? `/${id}` : ''}`,
  BODY_PARTS: (id?) => `${ROUTES.DASHBOARD()}/body_parts${id ? `/${id}` : ''}`,
  PATHOLOGIES: (id?) => `${ROUTES.DASHBOARD()}/pathologies${id ? `/${id}` : ''}`,
  PATHOLOGY_DETAILS: (id?) => `${ROUTES.DASHBOARD()}/pathology_details${id ? `/${id}` : ''}`,
  DIAGNOSTICS: (id?) => `${ROUTES.DASHBOARD()}/diagnostics${id ? `/${id}` : ''}`,
  BALANCE_TRANSACTIONS: (id?) => `${ROUTES.DASHBOARD()}/balance_transactions${id ? `/${id}` : ''}`,
  USER_REQUESTS: (id?) => `${ROUTES.DASHBOARD()}/user_requests${id ? `/${id}` : ''}`,
  QUOTES: (id?) => `${ROUTES.DASHBOARD()}/quotes${id ? `/${id}` : ''}`,
  DIAGNOSTIC_PATHOLOGY_DETAILS: (id) => `${ROUTES.DIAGNOSTICS(id)}/pathology_details`,
  DIAGNOSTIC_RESULT: (id) => `${ROUTES.DIAGNOSTICS(id)}/result`,
  LEGALS: () => `/legals`
};