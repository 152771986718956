import * as yup from "yup";
import YupPassword from 'yup-password';
YupPassword(yup);

const resetPasswordFormValidationSchema = yup.object().shape({
  password: yup.string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères .")
    .minLowercase(1, 'Le mot de passe doit contenir au moins une minuscule.')
    .minUppercase(1, 'Le mot de passe doit contenir au moins une majuscule.')
    .minNumbers(1, 'Le mot de passe doit contenir au moins un chiffre.')
    .minSymbols(1, 'Le mot de passe doit contenir au moins un caractère spécial.')
    .required("Ce champ est requis."),
  password_confirmation: yup.string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères .")
    .minLowercase(1, 'Le mot de passe doit contenir au moins une minuscule.')
    .minUppercase(1, 'Le mot de passe doit contenir au moins une majuscule.')
    .minNumbers(1, 'Le mot de passe doit contenir au moins un chiffre.')
    .minSymbols(1, 'Le mot de passe doit contenir au moins un caractère spécial.')
    .required("Ce champ est requis."),
  reset_password_token: yup.string().min(6).required("errors.form.required.field"),
});

export default resetPasswordFormValidationSchema;
