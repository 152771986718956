import {PageContainer} from '../../../_ui/page';
import Human from '../../../_ui/human';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useAddPathologyDetailToPathology, useCreatePathology,
  usePathology, useRemovePathologyDetailToPathology,
  useUpdatePathology
} from '../../../../_hooks/pathology/use-pathologies';
import {useRef, useState} from 'react';
import {PathologyDetailsTable} from '../pathology_details';
import Button from '../../../_ui/button';
import {Form, Select, TextField} from '../../../_ui/form';
import {useFormik} from 'formik';
import {INITIAL_FORM_VALUES} from './config';
import pathologyFormValidationSchema from './validation-schema';
import TabbedNavigation from '../../../_ui/tabbed-navigation';
import {APIBodyPart, APIPathologyBlob} from '../../../../_services/api/_helpers/api-types';
import {usePathologyBlobs} from '../../../../_hooks/pathology-blobs/use-pathology-blobs';
import * as React from 'react';
import FlexToolBox from '../../../_ui/tool-boxes/flex-tool-box';
import {useTranslation} from 'react-i18next';
import PathologyDetailSearch from '../pathology_details/PathologyDetailSearch';
import PathologySearch from './PathologySearch';
import BodyPartToolBox from '../../../_ui/tool-boxes/body-part-tool-box';
import {ROUTES} from '../../../../router/routes';

const PathologyForm = ({pathology, ...rest}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {createPathology} = useCreatePathology();
  const {updatePathology} = useUpdatePathology();

  const { errors, handleChange, handleSubmit, isSubmitting, values, isValid, touched, handleBlur, setSubmitting } = useFormik({
    initialValues: pathologyFormValidationSchema.cast(pathology?.id ? pathology : INITIAL_FORM_VALUES),
    onSubmit: async (formValues) => {
      if (formValues?.id) {
        await updatePathology(pathologyFormValidationSchema.cast(formValues));
      } else {
        const pathologyCreated = await createPathology(pathologyFormValidationSchema.cast(formValues));
        setTimeout(() => navigate(ROUTES.PATHOLOGIES(pathologyCreated.id)), 500);
      }
      setSubmitting(false);
    },
    validationSchema: pathologyFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Form {...rest}>
      <TextField
        required
        smallVerticalPadding
        name="text"
        label={"Nom de la pathologie"}
        value={values.text}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.text && !!errors.text}
        errorMessage={errors.text}
      />
      <Select
        required
        name="status"
        label={"Statut"}
        value={values.status}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.status && !!errors.status}
        errorMessage={errors.status}
      >
        <option disabled value="">Choisir un statut</option>
        {Object.keys(t('enums.pathology.status')).map((status, index) => (
          <option key={index} value={status}>
            {t(`enums.pathology.status.${status}`)}
          </option>
        ))}
      </Select>
      <Select
        required
        name="category"
        label={"Catégorie"}
        value={values.category}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.category && !!errors.category}
        errorMessage={errors.category}
      >
        <option disabled value="">Choisir une catégorie</option>
        {Object.keys(t('enums.pathology.category')).map((category, index) => (
          <option key={index} value={category}>
            {t(`enums.pathology.category.${category}`)}
          </option>
        ))}
      </Select>
      <Select
        required
        name="recurrent"
        label={"Pathologie récurrente"}
        value={+values.recurrent}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.recurrent && !!errors.recurrent}
        errorMessage={errors.recurrent}
      >
        {Object.keys(t('enums.pathology.recurrent')).map((recurrentValue, index) => (
          <option key={index} value={index}>
            {t(`enums.pathology.recurrent.${recurrentValue}`)}
          </option>
        ))}
      </Select>
      <TextField
        smallVerticalPadding
        name="popover"
        label={"Pop over"}
        value={values.popover}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={touched.popover && !!errors.popover}
        errorMessage={errors.popover}
      />
      <Button
        type="submit"
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit}
      >
        Enregistrer
      </Button>
    </Form>
  );
};

const PathologyInformation = ({pathologyId}) => {
  const {pathology} = usePathology({id: pathologyId});

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      {(pathologyId === 'new' || pathology) && <PathologyForm pathology={pathology || {}}/>}
    </div>
  );
};

const PathologyHelpers = ({pathologyId}) => {
  const {addPathologyDetailToPathology, isLoading: addIsLoading} = useAddPathologyDetailToPathology();
  const {removePathologyDetailToPathology, isLoading: removeIsLoading} = useRemovePathologyDetailToPathology();
  const isLoading = addIsLoading || removeIsLoading;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <PathologyDetailSearch
        onChange={async pathologyDetail => {
          await addPathologyDetailToPathology({
            id: pathologyId,
            pathology_detail_id: pathologyDetail.id,
          });
        }}
      />
      <PathologyDetailsTable
        key={isLoading?.toString()}
        readonly={true}
        payload={{filters: {pathology_id: [pathologyId]}}}
        onDelete={async pathologyDetail => {
          await removePathologyDetailToPathology({
            id: pathologyId,
            pathologyDetailId: pathologyDetail.id,
          });
        }}
      />
    </div>
  );
};

const Draw = ({pathologyId}) => {
  const pictureRef = useRef();
  const [bodyPart, setBodyPart] = useState<APIBodyPart | null>(null);
  const [pathologyIdSrc, setPathologyIdSrc] = useState<number>(pathologyId)
  const {pathologyBlobs: originalPathologyBlobs} = usePathologyBlobs({
    filters: {
      pathology_id: [pathologyId],
    },
  });
  const {pathologyBlobs, isLoading: pathologyBlobsIsLoading, getPathologyBlobs} = usePathologyBlobs({
    filters: {
      pathology_id: [pathologyIdSrc],
    },
  });

  const currentBodyPart = bodyPart;
  const blobs = originalPathologyBlobs?.list?.map(originalPathologyBlob => {
    const pathologyBlobFound = pathologyBlobs?.list?.find(pb => pb.body_part_id === originalPathologyBlob.body_part_id);
    return ({
      ...originalPathologyBlob,
      image_state_editor: (pathologyBlobFound || originalPathologyBlob)?.image_state_editor,
    });
  });
  pathologyBlobs?.list?.forEach(pathologyBlob => {
    const presentBodyPartIds = blobs.map(blob => blob.body_part_id);
    if (!presentBodyPartIds.includes(pathologyBlob.body_part_id)) {
      blobs.push({
        pathology_id: pathologyId,
        body_part_id: pathologyBlob.body_part_id,
        image_state_editor: pathologyBlob.image_state_editor,
      } as APIPathologyBlob);
    }
  });

  // @ts-ignore
  const currentPathologyBlob = blobs?.find(blob => blob.body_part_id === currentBodyPart?.id) || {
    pathology_id: pathologyId,
    body_part_id: currentBodyPart?.id,
  } as APIPathologyBlob;

  const getActions = () => {
    return pictureRef.current;
  };

  const schemaReadyToLoad = !pathologyBlobsIsLoading;

  if (!schemaReadyToLoad) {
    return null;
  }

  return (
    <div style={{display: 'flex', flex: 1, gap: 10, flexDirection: 'column', boxSizing: 'border-box'}}>
      <PathologySearch
        onChange={(pathology) => setPathologyIdSrc(pathology.id)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          rowGap: 4,
          columnGap: 20,
          flexWrap: 'wrap',
        }}
      >
        <BodyPartToolBox
          selectedBodyPart={currentBodyPart}
          setSelectedBodyPart={setBodyPart}
        />
        <FlexToolBox
          readonly={false}
          pathologyBlob={currentPathologyBlob}
          getActions={getActions}
          direction="row"
          onRefresh={() => setTimeout(getPathologyBlobs, 200)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          gap: 20,
          boxSizing: 'border-box'
        }}
      >
        <div style={{flex: 1, flexDirection: 'row', alignSelf: 'stretch'}}>
          {currentBodyPart?.id && (
            <Human
              ref={pictureRef}
              imageStateEditor={currentPathologyBlob.image_state_editor}
              bodyPart={currentBodyPart}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const PathologyPage = () => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<string>('information');

  const items = [
    {
      key: 'information',
      element: <span>Informations</span>,
      onClick: () => setActiveTab('information'),
      Component: () => <PathologyInformation pathologyId={id}/>,
    },
  ];

  if (id !== 'new') {
    items.push(
      {
        key: 'pathology_helpers',
        element: <span>Pathologies Helpers</span>,
        onClick: () => setActiveTab('pathology_helpers'),
        Component: () => <PathologyHelpers pathologyId={id}/>,
      },
      {
        key: 'draw',
        element: <span>Écorchés</span>,
        onClick: () => setActiveTab('draw'),
        Component: () => <Draw pathologyId={id}/>,
      },
    );
  }

  const categoryKey = activeTab || items[0]?.key;
  const currentItemIndex = items.findIndex((item) => item.key === categoryKey);
  const CurrentComponent = items[currentItemIndex].Component;

  return (
    <PageContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        height: '100svh',
        width: '100%',
      }}
    >
      <TabbedNavigation
        items={items}
        defaultActive={currentItemIndex}
      />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 10,
          width: '100%',
        }}
      >
        <CurrentComponent/>
      </div>
    </PageContainer>
  );
};

export default PathologyPage;