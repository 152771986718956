import type { Pagination } from "@table-library/react-table-library/types/pagination";

const PaginationDisplay = ({pagination, nodes}:{pagination:Pagination<any>, nodes:any[]}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <span>Nombre de pages: {pagination.state.getTotalPages(nodes)}</span>

      <span>
        Page:{" "}
        {pagination.state.getPages(nodes).map((_, index) => (
          <button
            key={index}
            type="button"
            style={{
              fontWeight: pagination.state.page === index ? "bold" : "normal",
            }}
            onClick={() => pagination.fns.onSetPage(index)}
          >
            {index + 1}
          </button>
        ))}
      </span>
    </div>
  );
};

export default PaginationDisplay;
