import styled from 'styled-components';

const DrawActionButton = styled.button<{$custombg?: string}>`
  border: none;
  border-radius: 12px;

  background: ${(props) => props.$custombg || '#F5F5F5'}; 

  /* Std-blur */
  backdrop-filter: blur(25px);

  width: 44px;
  min-width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export default DrawActionButton;