import { useMutation, useQueryClient } from "react-query";
import { setAuthToken } from "../../_services/utils/token";
import { AuthAPIService } from "../../_services/api";
import { LoginSuccess } from "../../_services/api/auth/types";
import { userKeys } from "../user/queries";
import {useAuthContext} from '../../_contexts/auth';
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/routes";

export function useLogin() {
  const { setIsLoggedIn, refetchUser } = useAuthContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate()

  const { mutate, isLoading, isError, error } = useMutation(
    userKeys.login(),
    (formData: object) => AuthAPIService.login(formData),
    {
      onSuccess: async({ data, headers }: LoginSuccess) => {
        queryClient.setQueryData(userKeys.user, data.user);
        //Set token in Axios instance
        AuthAPIService.setAuthToken(headers['authorization']);

        setAuthToken(headers['authorization']);
        setIsLoggedIn(true);
        await refetchUser()
        navigate(ROUTES.DASHBOARD());
      },
      useErrorBoundary: false,
      retry: false,
    }
  );
  return {
    login: mutate,
    isLoading,
    isError,
    error,
  };
}
