import styled from 'styled-components';
import {useEffect, useRef, useState} from 'react';
import {REACT_APP_API_URL} from '../../../_config/env';
import {API_NEMO_ROUTES} from '../../../_services/api/config/config';
import {useUpdateUser} from '../../../_hooks/user/use-users';
import {useAuthContext} from '../../../_contexts/auth';
import Monogram from '../monogram';
import {DirectUploadProvider} from 'react-activestorage-provider';
import {ReactComponent as CameraIcon} from '../../../_icons/camera.svg';

const CameraCaption = styled(CameraIcon)`
  position: absolute;
  right: 5px;
  bottom: 3px;
  width: 28px;
  height: 28px;
  background-color: var(--Background-Primary, #FFF);
  border-radius: 50%;
  cursor: pointer;
`;

export const UploadProvider = ({onSuccess, multiple=false, name, id, children, inputProps={}, maxFileSize = 10*1024*1024}) => {
  const [internalValue, setInternalValue] = useState();
  const _internalName = `__internal_${name}_label${id || ''}`;
  const inputRef = useRef();

  useEffect(() => {
    // @ts-ignore
    const files = inputRef?.current?.files || [];
    // @ts-ignore
    if (internalValue && files[0]?.name) {
      const blobUrls = Array.from(files).map((file: File, index: number) => ({
        signed_id: internalValue[index],
        // @ts-ignore
        filename: file?.name,
        // @ts-ignore
        url: `${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_REDIRECT}/${internalValue[index]}/${file?.name}`,
      }));
      onSuccess(multiple ? blobUrls : blobUrls[0]);
    }
  }, [internalValue]);

  const handleSuccess = (response) => {
    setInternalValue(response);
  }

  return (
    <DirectUploadProvider
      directUploadsPath={`${REACT_APP_API_URL}${API_NEMO_ROUTES.ACTIVE_STORAGE_DIRECT_UPLOADS}`}
      onSuccess={handleSuccess}
      multiple={multiple}
      render={({handleUpload, uploads, ready}) => (
        <div>
          <input
            ref={inputRef}
            id={_internalName}
            name={_internalName}
            type="file"
            disabled={!ready}
            onChange={e => {
              const tooBigFile = [...new Array(e.currentTarget.files.length)].map((_, i) => e.currentTarget.files.item(i)).find(file => file.size > maxFileSize);
              if (tooBigFile) {
                alert(`Erreur lors du téléchargement de votre document. Assurez-vous que sa taille ne dépasse pas ${maxFileSize/1024/1024} Mo.`);
                e.currentTarget.value = "";
              } else {
                handleUpload(e.currentTarget.files);
              }
            }}
            multiple={multiple}
            {...inputProps}
            style={{display: 'none'}}
          />
          <label htmlFor={_internalName}>
            {children}
          </label>
        </div>
      )}
    />
  );
};

const Avatar = () => {
  const {updateUser} = useUpdateUser();
  const {user} = useAuthContext();

  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setAvatarUrl(user.avatar?.url || '');
  }, [user.avatar?.url]);

  const handleSuccess = async (response) => {
    if (response.signed_id) {
      // @ts-ignore
      await updateUser({id: user.id, avatar: {signed_id: response.signed_id}});
    }
    setAvatarUrl(response.url);
  };

  return (
    <div
      style={{
        margin: '0 20px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <UploadProvider
        id={"avatar"}
        name={"avatar"}
        onSuccess={handleSuccess}
        inputProps={{
          accept: "image/jpeg,image/png"
        }}
      >
        <Monogram
          style={{
            position: 'relative',
            border: 'none',
            fontSize: '4rem',
            width: 120,
            height: 120,
            cursor: 'pointer',
          }}
          $backgroundImageUrl={avatarUrl}
        >
          {avatarUrl ? null : user.first_name.charAt(0)}
          <CameraCaption/>
        </Monogram>
      </UploadProvider>
    </div>
  );
};

export default Avatar;