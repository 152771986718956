import styled from "styled-components";
import { PageContainer } from "../../_ui/page";
import { ReactComponent as MascottePrimary } from "../../../assets/illustrations/mascotte-primary.svg";
import Button from "../../_ui/button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import { Carousel } from "nuka-carousel";
import { useAuthContext } from "../../../_contexts/auth";
import { useEffect } from "react";

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 480px;
  width: 100%;
  align-self: center;
  justify-content: space-between;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
  box-sizing: border-box;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--L, 24px);
  background: var(--Background-Secondary, #fafafa);

  padding: var(--XL, 24px) var(--XXL, 32px);
  flex-shrink: 0;
  align-self: stretch;

  h1 {
    color: #000;
    text-align: center;
    align-self: stretch;
    font-feature-settings: "liga" off, "clig" off;

    /* Mobile/friendlyTitle */
    font-family: "Mochiy Pop One";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 123.81% */
  }

  p {
    margin-top: 48px;
    max-width: 75%;
  }
`;

const ButtonsContainer = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;

  > button {
    width: 100%;
  }
`;

const CarouselSlide = styled.div`
  min-width: ${window.innerWidth}px;
`;

const CarouselContainer = styled.div`
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
`;

const HomePage = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      navigate(ROUTES.DASHBOARD());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginPageContainer>
      {/*<span/>*/}
      <div style={{ maxWidth: window.innerWidth + "px" }}>
        <Carousel
          showDots
          // autoplay
          // autoplayInterval={5000}
          className="carousel-onboarding"
        >
          <CarouselSlide>
            <CarouselContainer>
              <Header>
                <MascottePrimary />
                <h1>Un nouvel élan pour les diagnostics kinés</h1>
                <p>
                  Bienvenue sur NEMO, ton nouvel assistant pour des diagnostics
                  rapides, précis et fiable.
                </p>
              </Header>
            </CarouselContainer>
          </CarouselSlide>

          <CarouselSlide>
            <CarouselContainer>
              <Header>
                <MascottePrimary />
                <h1>Dessine, clique, et obtiens ton diagnostic</h1>
                <p>
                  Simple, efficace, intuitif ! Pas de complexité inutile, juste
                  l’essentiel.
                </p>
              </Header>
            </CarouselContainer>
          </CarouselSlide>

          <CarouselSlide>
            <CarouselContainer>
              <Header>
                <MascottePrimary />
                <h1>Une solution née du terrain</h1>
                <p>
                  NEMO, c'est l'initiative d'un kiné passionné pour simplifier
                  le quotidien de tous les praticiens.
                </p>
              </Header>
            </CarouselContainer>
          </CarouselSlide>

          <CarouselSlide>
            <CarouselContainer>
              <Header>
                <MascottePrimary />
                <h1>À partir d’aujourd’hui, tu n’es plus seul.</h1>
                <p>
                  Parfois, on hésite à demander de l’aide. Avec NEMO, avance
                  sereinement et en toute confiance.
                </p>
              </Header>
            </CarouselContainer>
          </CarouselSlide>
        </Carousel>
      </div>

      <ButtonsContainer>
        <Button onClick={() => navigate(ROUTES.LOGIN())}>Connexion</Button>
        <Button $display="secondary" onClick={() => navigate(ROUTES.SIGNUP())}>
          Inscription
        </Button>
      </ButtonsContainer>
      <div onClick={() => navigate(ROUTES.LEGALS())}>
        <span>Informations Légales</span>
      </div>
    </LoginPageContainer>
  );
};

export default HomePage;
