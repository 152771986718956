import * as yup from "yup";
import YupPassword from 'yup-password';
import { calculateAge } from "../../signup/validation-schema";
YupPassword(yup);

const userFormValidationSchema = yup.object().shape({
  first_name: yup.string()
    .min(1)
    .test(
      "test", 
      "Le nom et le prénom doivent contenir uniquement des lettres, des espaces ou des tirets.", 
      value => !!!value.match(/[^a-zA-Z\s\^-]/)
    )
    .required("Ce champ est requis."),
  last_name: yup.string()
    .min(1)
    .test(
      "test", 
      "Le nom et le prénom doivent contenir uniquement des lettres, des espaces ou des tirets.", 
      value => !!!value.match(/[^a-zA-Z\s\^-]/)
    )
    .required("Ce champ est requis."),
  email: yup.string().email("L’adresse email saisie est invalide.").min(1).required("Ce champ est requis."),
  password: yup.string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères .")
    .minLowercase(1, 'Le mot de passe doit contenir au moins une minuscule.')
    .minUppercase(1, 'Le mot de passe doit contenir au moins une majuscule.')
    .minNumbers(1, 'Le mot de passe doit contenir au moins un chiffre.')
    .minSymbols(1, 'Le mot de passe doit contenir au moins un caractère spécial.')
    .required("Ce champ est requis."),
  birth_date: yup.string()
  .test(
    "date-test",
    "date must be 16 year old",
    (value) => {    
      return calculateAge(value) > 16;
    }
  ),
});

export {
  userFormValidationSchema,
};
