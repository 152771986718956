import {PageContainer} from '../../_ui/page';
import Button from '../../_ui/button';
import {Form, TextField} from '../../_ui/form';
import styled from 'styled-components';
import {useLogin} from '../../../_hooks/auth';
import {useFormik} from 'formik';
import {INITIAL_FORM_VALUES} from './config';
import loginFormValidationSchema from './validation-schema';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../router/routes';
import {useState} from 'react';
import {resetPassword, sendConfirmationInstructionsToUser} from '../../../_services/api/auth';
import {ErrorMessageContainer} from '../../_ui/form/text-field';
import InfoMessage from '../../_ui/info-message';
import * as React from 'react';
import {ReactComponent as MascottePrimary} from '../../../assets/illustrations/mascotte-primary.svg';

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 50}}
    >
      <InfoMessage
        Illustration={<MascottePrimary/>}
        // Title={<h1>Let’s go !</h1>}
        Text={(
          <p>
            Si tu as un compte chez nous, tu recevras un email dans les minutes qui viennent.
            <br/>
            <br/>
            <b>Pense à vérifier tes spams !</b>
          </p>
        )}
      />
      <Form>
        <Button
          onClick={() => navigate(ROUTES.HOME())}
        >
          Revenir à l’accueil
        </Button>
      </Form>
    </div>
  );
};
const ResetPasswordEmailForm = ({back}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const {errors, handleChange, handleSubmit, values, isValid, touched, handleBlur, isSubmitting} = useFormik({
    initialValues: {email: ''},
    onSubmit: async (formValues) => {
      await resetPassword(formValues);
      setIsSuccess(true);
    },
    validationSchema: loginFormValidationSchema.pick(['email']),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  if (isSuccess) {
    return <ResetPasswordSuccess/>
  }

  return (
    <>
      <div style={{display: 'flex', flex: 1, justifyContent: 'center', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <h1>Mot de passe oublié</h1>
          <p>Ne t'inquiète pas ! Ça arrive. Saisis l’adresse mail associée à ton compte.</p>
        </div>
        <Form>
          <TextField
            type="email"
            name="email"
            required
            placeholder={"Adresse email"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            hasError={touched.email && !!errors.email}
            errorMessage={errors.email}
          />

          <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 16}}>
            <Button
              style={{flex: 1}}
              $display="secondary"
              onClick={() => back()}
            >
              Retour
            </Button>
            <Button
              style={{flex: 1}}
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit}
            >
              Suivant
            </Button>
          </div>
        </Form>
      </div>
      <div>
        <span>Un problème ? </span><Link to={""}>Contacter le support</Link>
      </div>
    </>
  );
};

const LoginPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-height: 100svh;
  height: 100svh;
  padding: var(--XL, 24px) var(--XL, 24px) 56px var(--XL, 24px);
`;

const LoginPage = () => {
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [isInstructionsSent, setIsInstructionsSent] = useState<boolean>(false);
  const { login, isLoading, isError, error } = useLogin();
  const { errors, handleChange, handleSubmit, values, isValid, touched, handleBlur } = useFormik({
    initialValues: INITIAL_FORM_VALUES,
    onSubmit: (formValues) => login(formValues),
    validationSchema: loginFormValidationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const sendConfirmationEmail = async () => {
    await sendConfirmationInstructionsToUser({email: values.email});
    setIsInstructionsSent(true);
  };

  return (
    <LoginPageContainer>
      {resetPassword
        ? <ResetPasswordEmailForm back={() => setResetPassword(false)}/>
        : (
          <>
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: 32,
                paddingBottom: 64,
              }}
            >
              <h1>Connexion</h1>
              <Form>
                <TextField
                  type="email"
                  name="email"
                  required
                  placeholder={"Adresse email"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  hasError={touched.email && !!errors.email}
                  errorMessage={errors.email}
                />
                <TextField
                  name="password"
                  required
                  type="password"
                  placeholder={"Mot de passe"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  hasError={touched.password && !!errors.password}
                  errorMessage={errors.password}
                />
                {isError && (
                  <ErrorMessageContainer style={{textAlign: 'center'}}>
                    <span>
                      {
                        // @ts-ignore
                        error?.response?.data?.error_type !== "unconfirmed"
                          ? "L’email ou le mot de passe est incorrect."
                          : isInstructionsSent
                            ? "Pour valider ton inscription, clique sur le bouton 'Valider mon compte' dans l'email que tu viens de recevoir."
                            : <>Pour valider ton inscription, clique sur le bouton 'Valider mon compte' dans l'email que tu as reçu lors de ton inscription. <a onClick={sendConfirmationEmail}>Renvoyer l'email</a></>
                      }
                    </span>
                  </ErrorMessageContainer>
                )}
                <div>
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() => setResetPassword(true)}
                  >
                    Mot de passe oublié ?
                  </span>
                </div>
                <Button
                  disabled={!isValid || isLoading}
                  onClick={handleSubmit}
                >
                  Connexion
                </Button>
              </Form>
            </div>
            <div>
              <span>Je n’ai pas de compte. </span><Link to={ROUTES.SIGNUP()}>Inscription</Link>
            </div>
          </>
        )
      }
    </LoginPageContainer>
  );
};

export default LoginPage;