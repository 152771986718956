import * as yup from "yup";
import YupPassword from 'yup-password';
YupPassword(yup);

export const calculateAge = (dob: string | number | Date) => {  
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }  
  return age;
};

const nameFormValidationSchema = yup.object().shape({
  first_name: yup.string()
    .min(1)
    .test(
      "test", 
      "Le nom et le prénom doivent contenir uniquement des lettres, des espaces ou des tirets.", 
      value => !!!value.match(/[^a-zA-Z\s\^-]/)
    )
    .required("Ce champ est requis."),
  last_name: yup.string()
    .min(1)
    .test(
      "test", 
      "Le nom et le prénom doivent contenir uniquement des lettres, des espaces ou des tirets.", 
      value => !!!value.match(/[^a-zA-Z\s\^-]/)
    )
    .required("Ce champ est requis."),
  birth_date: yup.string()
    .test(
      "date-test",
      "date must be 16 year old",
      (value) => {    
        return calculateAge(value) > 16;
      }
    )
    .required("Ce champ est requis."),
  student: yup.boolean(),
});

const professionalNumberFormValidationSchema = yup.object().shape({
  professional_number: yup.string().min(1).required("Ce champ est requis."),
});

const sponsorFormValidationSchema = yup.object().shape({
  sponsor_code: yup.string().length(5),
});

const credentialsFormValidationSchema = yup.object().shape({
  email: yup.string().email("L’adresse email saisie est invalide.").min(1).required("Ce champ est requis."),
  password: yup.string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères .")
    .minLowercase(1, 'Le mot de passe doit contenir au moins une minuscule.')
    .minUppercase(1, 'Le mot de passe doit contenir au moins une majuscule.')
    .minNumbers(1, 'Le mot de passe doit contenir au moins un chiffre.')
    .minSymbols(1, 'Le mot de passe doit contenir au moins un caractère spécial.')
    .required("Ce champ est requis."),
  terms_of_use_accepted_at: yup.string().min(1).required("Vous devez accepter les conditions d’utilisation pour continuer."),
});

const studentSchoolFormValidationSchema = yup.object().shape({
  school_name: yup.string().min(1).required("Ce champ est requis."),
  school_country: yup.string().min(1).required("Ce champ est requis."),
});

const studentSchoolYearFormValidationSchema = yup.object().shape({
  school_year: yup.number().required().oneOf([1, 2, 3, 4, 5])
});

const studentMailAndDocumentsFormValidationSchema = yup.object().shape({
  email: yup.string().email("L’adresse email saisie est invalide.").min(1).required("Ce champ est requis."),
  attachments: yup.array().of(
    yup.object().shape({
      filename: yup.string().required("Ce champ est requis."),
      url: yup.string().required("Ce champ est requis."),
      signed_id: yup.string().required("Ce champ est requis."),
    })
  ).min(1).required("Ce champ est requis."),
});

export {
  nameFormValidationSchema,
  professionalNumberFormValidationSchema,
  credentialsFormValidationSchema,
  studentSchoolFormValidationSchema,
  studentSchoolYearFormValidationSchema,
  studentMailAndDocumentsFormValidationSchema,
  sponsorFormValidationSchema,
};
