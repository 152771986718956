import * as yup from "yup";

const pathologyFormValidationSchema = yup.object().shape({
  id: yup.number().notRequired(),
  text: yup.string().required("errors.form.required.field"),
  status: yup.string().oneOf(['draft', 'active']).required("errors.form.required.field"),
  category: yup.string().oneOf(['neurology', 'musculotendinous', 'osteoarticular', 'other']).required("errors.form.required.field"),
  recurrent: yup.boolean().transform((v) => !!v).required("errors.form.required.field"),
  popover: yup.string().notRequired(),
});

export default pathologyFormValidationSchema;
