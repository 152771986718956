import * as yup from "yup";

const loginFormValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("L’adresse email saisie est invalide.")
    .required("Ce champ est requis."),
  password: yup.string().min(6).required("Ce champ est requis."),
});

export default loginFormValidationSchema;
