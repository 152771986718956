import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Modal from '../modal';

import {ReactComponent as MascotPrimary} from '../../../assets/illustrations/mascotte-primary.svg';
import {ReactComponent as MascotBlue} from '../../../assets/illustrations/mascotte-blue.svg';
import {ReactComponent as MascotRed} from '../../../assets/illustrations/mascotte-red.svg';
import {ReactComponent as MascotSurprise} from '../../../assets/illustrations/mascotte-surprise.svg';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--XL, 32px);
  background: var(--Colors-Neutral-white, #FFF);
  padding: 32px 44px 36px 44px;
  gap: 24px;
  flex-shrink: 0;
  box-shadow: 0px 4px 250px 0px rgba(0, 0, 0, 0.25);
`;

const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: #FAFAFA;
  box-sizing: border-box;
  color: var(--Text-primary, #1D1D1B);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  /* Mobile/friendlyTitle */
  font-family: "Mochiy Pop One";
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 152.381% */
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  h1 {
    color: var(--Text-primary, #1D1D1B);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    /* Mobile/friendlyTitle */
    font-family: "Mochiy Pop One";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 123.81% */
  }
`;

const ModalText = styled.div`
  color: var(--Text-primary-light, #636360);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.2px;
  white-space: pre-line;
  text-align: left;
  b {
    color: #99D41C;
  }
`;

const Illustrations = {
  Primary: MascotPrimary,
  Blue: MascotBlue,
  Red: MascotRed,
  Surprise: MascotSurprise,
};

const HelperModal = ({isOpen, translationKey, onClose}) => {
  const {t} = useTranslation();

  if (!isOpen || !translationKey){
    return null;
  }

  const Illustration = Illustrations[t(`popups.${translationKey}.illustration`)];

  //const buttonsObject = t(`popups.${translationKey}.buttons`, {returnObjects: true}) || {};
  // @ts-ignore
  //const isInverted = buttonsObject.inverted;

  return (
    // @ts-ignore
    <Modal
      withPadding
      isOpen={isOpen}
      onRequestClose={onClose}
      overlay={true}
    >
      <ModalContent>
        <ModalHeader>
          <Illustration/>
          <ModalTitle>{t(`popups.${translationKey}.title`)}</ModalTitle>
        </ModalHeader>
        <ModalText dangerouslySetInnerHTML={{__html: t(`popups.${translationKey}.text`)}}/>
      </ModalContent>
    </Modal>
  );
};

export default HelperModal;