import * as yup from "yup";

const pathologyDetailFormValidationSchema = yup.object().shape({
  id: yup.number().nullable().transform(v => v ? +v : null),
  category: yup.string().default('').oneOf([
    'event',
    'symptom_reproduction',
    'positive_test',
    'relevant_observation'
  ]).required("errors.form.required.field"),
  text: yup.string().min(1).default('').required("errors.form.required.field"),
  popover: yup.string().notRequired(),
});

export default pathologyDetailFormValidationSchema;
